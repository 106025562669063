import React, { useState } from "react";
import Lightbox from "./components/CoolLightbox";
import ImageMosaic from "./components/ImageMosaic";
import images from "../../data/Portfolio/portfolio.json";
import SectionTitle from "../UI/SectionTitle";

const ImageGallery = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [currentListIndex, setCurrentListIndex] = useState(0);

  const subImages = images[currentImageIndex].images;

  return (
    <>
      {/* react-photo-gallery */}
      <div className=" align-items-center fluid mt-10">
        <SectionTitle variant="dark" title="BUILDING GALLERY"
          heading="Storylines"
        />
      </div>
      <ImageMosaic
        images={images}
        handleClick={(e, { index }) => {
          setCurrentIndex(index);
          setOpen(true);
        }}
      />
      {/* react-spring-lightbox */}
      <Lightbox
        currentImageIndex={currentListIndex}
        setCurrentIndex={setCurrentListIndex}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        images={subImages}
      />
    </>
  );
};

export default ImageGallery;
