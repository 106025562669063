import React from 'react';


function HeaderConfig(props) {


    const MobileMenuHandler = () => {
        const offCanvasMenu = document.querySelector('.off-canvas-menu');
        offCanvasMenu.classList.add('active');
    }

    return (
        <div className="header-action  mt-lg-3 text-right">
            <a href="tel:00233264666612" className=" small tel-no"><i className="fa fa-phone" aria-hidden="true"></i> 0303 408 207</a><p></p>
            {/* <a href="tel:00233203855647" className="small tel-no">+233 203855647</a> */}

            {/* <button onClick={LoginRegHandler} className="btn-cog"><i className="fa fa-cog" /></button> */}
            <button onClick={MobileMenuHandler} className="  btn-menu d-lg-none"><i className="fa fa-bars fa-2x" /></button>
        </div >
    );
}

export default HeaderConfig;