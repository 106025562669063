import React, { Fragment } from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import TeamMember from '../templates/Team'
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const PageTeam = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="KNOW ABOUT TI-AN VENTURES' TEAM"
                content="Meet Our Expert Members. TI-AN Ventures always try to provide the best Business Solutions"
            />

            <TeamMember />
            <CallToAction />
            <Footer />
            <MobileMenu />
        </Fragment>
    );
};

export default PageTeam;