

import React, { useState } from 'react';
import { useForm, } from '@formspree/react';
import FormInput from '../../components/UI/Input';

function ContactForm() {
    const [state, handleSubmit] = useForm("mdoyowne");
    const [data, setData] = useState({ fname: '', lname: '', phone: '', email: '', message: '', sent: false, buttonText: 'Send Message', err: '' })

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(e.target.value)
        setData({
            [name]: value,
            buttonText: 'Send Message',
            // ...data,
        })
    }
    if (state.succeeded) {
        return <p>Thanks, we will get back to you!</p>;
    }
    return (
        <div className="contact-form-wrap">
            <div className="row">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <FormInput
                                tag={'input'}
                                type={'text'}
                                name={'first_name'}
                                placeholder={'First Name *'}
                                value={data.fname}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormInput
                                tag={'input'}
                                type={'text'}
                                name={'last_name'}
                                placeholder={'Last Name *'}
                                value={data.lname}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormInput
                                tag={'input'}
                                type={'email'}
                                name={'email_address'}
                                placeholder={'Email address *'}
                                value={data.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-md-6">
                            <FormInput
                                tag={'input'}
                                type={'text'}
                                name={'phone_no'}
                                placeholder={'Phone No'}
                                value={data.phone}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-12">
                            <FormInput
                                tag={'textarea'}
                                name={'con_message'}
                                placeholder={'Write Your Message *'}
                                value={data.message}
                                onChange={handleChange}
                            />

                            <FormInput
                                tag={'button'}
                                classes={'btn-outline'}
                                value={data.buttonText}
                            />

                            <div className="form-message" />
                        </div>
                    </div>

                </form>
            </div>

        </div>
    );
}


export default ContactForm;