import React from 'react';
import OffCanvas from "../UI/OffCanvas";
import NavbarItem from "../Header/Navbar/NavbarItem";

const MobileMenu = () => {
    return (
        <OffCanvas type="menu">
            <div className="res-mobile-menu bold font-weight-bold pad2x">
                <div className="mobile-nav my-5">
                    <NavbarItem />
                </div>
            </div>
        </OffCanvas>
    );
};

export default MobileMenu;