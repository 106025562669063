import React, { useEffect } from 'react';
import teamData from '../../data/Team/home-one';
import TeamMember from "../../components/Team/home-one/Member";

const TeamPage = () => {
    useEffect(() => {
        return () => {
            window.location.reload()
        }
    }, [])
    return (
        <div className="team-page-area-wrapper bg-offwhite sp-y">
            <div className="container mt-50">
                <div className="row mtn-30">
                    {
                        teamData.map(member => (
                            <TeamMember key={member.id} type={'page'} id={member.id} name={member.name} designation={member.designation} profilePic={member.profilePic} />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default TeamPage;