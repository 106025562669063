import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from '../../assets/img/logo-dark.png'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${"/"}`}>
                                    <img src={Logo} alt="Logo" />
                                </Link>

                                <Text>
                                    TI-VENTURES at your service.
                                </Text>

                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} TI-AN Ventures Ltd. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${"/about"}`}>Our company</Link></LI>
                                <LI><Link to={`${"/contact"}`}>Contact us</Link></LI>
                                <LI><Link to={`${"/services"}`}>Our services</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><a href="https://facebook.com/tianventures" rel="noopener noreferrer" target={'_blank'}>Facebook</a></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                Sakumono Estates, <br />Tema, Ghana <br />
                                www.tianventures.com <br />
                                (+233) 542926726, (+233) 203855647<br />
                                (+233) 303408207
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;