import React, { Fragment } from 'react';
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header />
            <ServiceDetails />
            <Funfact />
            <CallToAction />
            <Footer />
            <MobileMenu />
        </Fragment>
    );
};

export default PageServiceDetails;