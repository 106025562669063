import React from 'react';

const FormInput = ({ tag, type, name, placeholder, classes, value, onChange, onSubmit }) => {
    return (
        <div className="single-input-item">
            <label>
                {(() => {
                    if (tag === 'input') {
                        return <input type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} className={classes} />
                    } else if (tag === 'textarea') {
                        return <textarea name={name} cols="30" rows="7" placeholder={placeholder} value={value} onChange={onChange} className={classes} required />
                    } else if (tag === 'button') {
                        return <button value={"asdf"} onChange={onSubmit} className={`btn-outline ${classes}`} >{value}</button>
                    }
                })()}
            </label>
        </div>
    );
};

export default FormInput;