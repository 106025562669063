import React from 'react';

function Member(props) {
    return (
        (
            <div className="card  m-1" style={{ width: 18 + "rem" }}>
                <div className="card-body">
                    <h5 className="card-title">{props.name}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{props.level}</h6>
                    <p className="card-text">{props.designation}</p>
                    {/* <a href="#" class="card-link">Another link</a> */}
                </div>
            </div>
        )
    );
}

export default Member;