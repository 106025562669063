import React, { useEffect } from 'react';
// import Form from "./Form";
import ContactInfo from "./ContactInfo";
import ContactForm from './ContactForm';

const ContactPage = () => {

    useEffect(() => {
        return () => {
            window.location.reload()
        }
    }, [])
    return (
        <div className={'contact-page-area-wrapper slider-push sp-y'}>
            <div className="container">
                <div className="contact-content-wrap">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-form-area contact-method">
                                <h3>Send us a Message</h3>
                                {/* <Form /> */}
                                <ContactForm />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="contact-information contact-method">
                                <div className="contact-info-con">
                                    <h3>Contact Info</h3>
                                    <ContactInfo
                                        address="Sakumono Estates, <br/>Tema, Ghana <br/>P.O. Box Sk 981, Sakumono Estates <br/>www.tianventures.com<br/>(+233) 264666612 | (+233) 203855647"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;