import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/*
* @ All pages Import
*/

import HomeOne from './pages/HomeOne'
import Service from './pages/Service'
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";

import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";
import Portfolio from './pages/portofolio';

const App = () => {
    return (

        <Router>
            <ScrollToTop>
                <Switch>
                    <Route exact path={'/'} component={HomeOne} />
                    <Route exact path={`${'/services'}`} component={Service} />
                    <Route exact path={`${'/service/:serviceID'}`} component={ServiceDetails} />
                    <Route exact path={`${"/team"}`} component={Team} />
                    <Route exact path={`${"/team-member/:teamID"}`} component={TeamDetails} />
                    <Route exact path={`${"/contact"}`} component={Contact} />
                    <Route exact path={`${"/portfolio"}`} component={Portfolio} />
                    <Route component={Error404} />
                </Switch>
            </ScrollToTop>
        </Router>

    );
};

export default App;