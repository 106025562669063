import React, { Fragment } from 'react';
import posed from 'react-pose';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageGallery from "../components/ImageGallery";
import MobileMenu from '../components/MobileMenu';


const ListContainer = posed.div({
  enter: { staggerChildren: 20 },
  exit: { staggerChildren: 20, staggerDirection: 0 }
});

const Item = posed.section({
  enter: { y: 0, opacity: 1 },
  exit: { y: 5, opacity: 0 }
});


const Portfolio = () => (
  <Fragment>
    <Header />
    <ListContainer>

      <Item className='container black_more'>
        <div className='row sm-10-hor'>
          <div className='col-12'>
            <div className='AppContainer'>
              <ImageGallery />
            </div>
          </div>

        </div>
      </Item>
    </ListContainer>
    <Footer />
    <MobileMenu />
  </Fragment>

);

export default Portfolio
