import React from 'react';
import SlickSlider from '../../UI/Slick'
import SliderData from '../../../data/Slider/home-1'
import { Link } from "react-router-dom";


const NextArrow = ({ className, onClick }) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-right" /></button>
    )
};

const PrevArrow = ({ className, onClick }) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-left" /></button>
    )
};

const Slider = () => {
    const settings = {
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 10000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    arrows: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <div className={'slider-area slider-push pt-4'}>
            <SlickSlider settings={settings}>
                {
                    SliderData.map(item => (
                        <div key={item.id}>
                            <div className="slider-item "
                                style={{ backgroundImage: `url(${require('../../../assets/img/' + item.bg)})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="slider-content">
                                                <h2 className="p-1 mb-1 text-light pt-5 text-shadow">{item.title}</h2>
                                                <div className="bg-dark rounded px-3 py-2 opacity-semi"><p className="text-white">{item.text}</p></div>
                                                <Link to={`${item.btnLink}`} className="btn btn-brand">{item.btnText}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </SlickSlider>
        </div>
    );
}
    ;

export default Slider;