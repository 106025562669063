import React from 'react';
import SectionTitle from "../../UI/SectionTitle";
import SlickSlider from "../../UI/Slick";
import Member from "./Member";

import teamBg from '../../../assets/img/team/team-bg.jpg';
import Teams from '../../../data/Team/home-one';

function Team() {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        className: "team-content-wrap slick-dots--light mtn-md-5",
        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    autoplaySpeed: 5000,
                    arrows: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                }
            },
            {
                breakpoint: 401,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <div className="team-area-wrapper bg-img sp-y" style={{ backgroundImage: `url(${teamBg})` }}>
            <div className="container-fluid">
                <div className="row align-items-center pl-10">
                    <div className=" align-items-center fluid">
                        <SectionTitle variant="light" title="Committed and Professional"
                            heading="TEAM"
                        />
                    </div>

                    <div className="col-lg-12 ">
                        <SlickSlider settings={settings}>
                            {
                                Teams.map(team => (
                                    <div key={team.id}>
                                        {<Member id={team.id} name={team.name} designation={team.designation} profilePic={team.profilePic} />}
                                    </div>
                                ))
                            }
                        </SlickSlider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Team;