import React from 'react';
import Slick from 'react-slick';
import "slick-carousel/slick/slick.scss";

function SlickSlider({ children, settings }) {
    return (
        <div >
            <Slick {...settings}>
                {children}
            </Slick>
        </div>
    );
}

export default SlickSlider;